<template>
  <div>
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >基础资料</van-divider
    >
    <van-row class="item">
      <van-col span="12" class="title">籍贯：</van-col>
      <van-col span="12" class="desc">{{ base.birthplaceName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">户口所在地：</van-col>
      <van-col span="12" class="desc">{{ base.regplaceName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">护照：</van-col>
      <van-col span="12" class="desc"
        ><van-tag
          :color="COLOR_M"
          v-if="base.passportImage !== undefined && base.passportImage !== ''"
          >查看照片</van-tag
        >
        <span v-else>未上传</span>
      </van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">居住地：</van-col>
      <van-col span="12" class="desc">{{ base.regionName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">详细地址：</van-col>
      <van-col span="12" class="desc">{{ base.address }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">身高：</van-col>
      <van-col span="12" class="desc">{{ base.stature }}CM</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">体重：</van-col>
      <van-col span="12" class="desc">{{ base.weight }}KG</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">视力：</van-col>
      <van-col span="12" class="desc"
        >左：{{ base.sightLeft }};右：{{ base.sightRight }}</van-col
      >
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">政治面貌：</van-col>
      <van-col span="12" class="desc">{{ base.politicalName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">学历：</van-col>
      <van-col span="12" class="desc">{{ base.qualifyName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">毕业院校：</van-col>
      <van-col span="12" class="desc">{{ base.school }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">专业：</van-col>
      <van-col span="12" class="desc">{{ base.major }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">毕业时间：</van-col>
      <van-col span="12" class="desc">{{ base.gradtime }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">毕业证：</van-col>
      <van-col span="12" class="desc"
        ><van-tag
          :color="COLOR_M"
          v-if="base.gradcert !== undefined && base.gradcert !== ''"
          @click="imageView(base.gradcert)"
          >查看照片</van-tag
        >
        <span v-else>未上传</span></van-col
      >
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">学籍验证报告：</van-col>
      <van-col span="12" class="desc">已上传</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">学位证：</van-col>
      <van-col span="12" class="desc"
        ><van-tag
          :color="COLOR_M"
          v-if="base.degreeCert !== undefined && base.degreeCert !== ''"
          @click="imageView(base.degreeCert)"
          >查看照片</van-tag
        >
        <span v-else>未上传</span></van-col
      >
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">英语等级：</van-col>
      <van-col span="12" class="desc">{{ base.englishName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">工作年限：</van-col>
      <van-col span="12" class="desc">{{ base.duration }}年</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">健康状况：</van-col>
      <van-col span="12" class="desc">{{ base.healthName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">身体纹身：</van-col>
      <van-col span="12" class="desc">{{ base.tattooName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">自我介绍：</van-col>
      <van-col span="12" class="desc">{{ base.introduce }}</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider, Tag } from 'vant'
export default {
  components: {
    [Divider.name]: Divider,
    [Tag.name]: Tag
  },
  data () {
    return {
      base: {
        stature: 150,
        weight: 75,
        politicalName: '无',
        qualifyName: '无',
        school: '',
        major: '',
        englishName: '无',
        regionName: '',
        duration: 1,
        healthName: '无',
        tattooName: '无'
      }
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveBase (personCode) {
      var pd = { personCode: personCode, group: 'BAS', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_BAS_BIRTHPLACE') {
            this.base.birthplaceName = obj.name
          } else if (element.key === 'PSN_BAS_REGPLACE') {
            this.base.regplaceName = obj.name
          } else if (element.key === 'PSN_BAS_PASSPORT') {
            this.base.passportImage = obj.imageCode
          } else if (element.key === 'PSN_BAS_REGION') {
            this.base.regionName = obj.name
          } else if (element.key === 'PSN_BAS_ADDRESS') {
            this.base.address = obj.value
          } else if (element.key === 'PSN_BAS_STATURE') {
            this.base.stature = obj.value
          } else if (element.key === 'PSN_BAS_WEIGHT') {
            this.base.weight = obj.value
          } else if (element.key === 'PSN_BAS_SIGHT') {
            this.base.sightLeft = obj.left
            this.base.sightRight = obj.right
          } else if (element.key === 'PSN_BAS_POLITICAL') {
            this.base.politicalName = obj.name
          } else if (element.key === 'PSN_BAS_QUALIFY') {
            this.base.qualifyName = obj.name
          } else if (element.key === 'PSN_BAS_SCHOOL') {
            this.base.school = obj.name
          } else if (element.key === 'PSN_BAS_MAJOR') {
            this.base.major = obj.name
          } else if (element.key === 'PSN_BAS_GRADTIME') {
            this.base.gradtime = obj.value
          } else if (element.key === 'PSN_BAS_GRADCERT') {
            this.base.gradcert = obj.code
          } else if (element.key === 'PSN_BAS_DEGREECERT') {
            this.base.degreeCert = obj.code
          } else if (element.key === 'PSN_BAS_ENGLISH') {
            this.base.englishName = obj.name
          } else if (element.key === 'PSN_BAS_DURATION') {
            this.base.duration = obj.value
          } else if (element.key === 'PSN_BAS_HEALTH') {
            this.base.healthName = obj.name
          } else if (element.key === 'PSN_BAS_TATTOO') {
            this.base.tattooName = obj.name
          } else if (element.key === 'PSN_BAS_INTRODUCE') {
            this.base.introduce = obj.value
          }
        })
      }
    },
    imageView (code) {
      this.$router.push({ path: '/mde/storage/imageView', query: { code: code } })
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
